import type {TrackingEvents} from "../types/tracking";

import {Log} from "../constants/log";
import {eventQBus} from "@otto-ec/event-q-bus";
import {tlEmptyStateCount} from "./labels/EmptyStateCount";
import {tlEmptyStateTime} from "./labels/EmptyStateTime";
import {tlSuggestRenderTime} from "./labels/SuggestRenderTime";
import {tlInitialSearchBoxTime} from "./labels/InitialSearchBoxTime";
import {tlSuggestTime} from "./labels/SuggestTime";
import {tlSearchType} from "./labels/SearchType";
import {SearchHistoryValue, TL_HIGGINS} from "./labels/higginsLabel";
import {get} from "svelte/store";
import {submitHistory} from "../store/HistoryStore";
import {SqrlLogger} from "../util/SqrlLogger";
import {tlInteraction} from "./labels/Interaction";
import {type DataContainer, type EventMergeContext, tracking as bct} from "@otto-ec/tracking-bct";
import {SQRL_EVENT} from "../events/sqrlEvents";

export class Tracking<L extends Log.TRACKING> {
  private log: SqrlLogger<L> = new SqrlLogger<L>(Log.TRACKING as L);

  /**
 *
 */
  get events(): TrackingEvents {
    return eventQBus<TrackingEvents>();
  }

  /**
 *
 *
 */
  trackOnNextPageImpression(urlTrackingData?: DataContainer) {
    const mergedTrackingData: DataContainer = {...urlTrackingData, ...this.trackingData};
    this.updateTrackingData(mergedTrackingData);
    this.sendOnNextPageImpression(mergedTrackingData)
    this.log.debug("Track on next PI submitting " + JSON.stringify(mergedTrackingData));
  }

  sendOnNextPageImpression(trackingData: DataContainer) {
    bct.trackOnNextPageImpression(trackingData);
  }

  /**
 *
 */
  trackSubmitEvent(trackingData: DataContainer) {
    bct.submitEvent(trackingData)
    this.log.debug("Track submitted " + JSON.stringify(trackingData));
  }


  /**
 *
 *
 */
  async trackCreateEventContext(eventContext: SQRL_EVENT) {
    const context: EventMergeContext = await bct.createEventMergeContext();
    this.events.emit(eventContext, context);
    this.log.debug("Tracking created event context " + eventContext);
  }

  /**
 *
 *
 *
 */
  trackSubmitEventMerge(trackingData: DataContainer, mergeContext: EventMergeContext) {
    bct.submitEventMerge(mergeContext.eventMergeId, trackingData);
    this.log.debug("Tracking submit event merge " + JSON.stringify(trackingData) + " with mergeId " + mergeContext.eventMergeId);
  }

  /**
 *
 *
 *
 */
  private updateTrackingData(trackingData: DataContainer) {
    const filterExists = get(submitHistory.store).some((item) => item.tags && item.tags.length > 0);
    if (filterExists) {
      trackingData[TL_HIGGINS.SEARCH_HISTORY] = [SearchHistoryValue.FILTER];
    }
    return trackingData;
  }

  private get trackingData(): DataContainer {
    return {
      ...tlEmptyStateCount.data,
      ...tlEmptyStateTime.data,
      ...tlSuggestTime.data,
      ...tlSuggestRenderTime.data,
      ...tlInitialSearchBoxTime.data,
      ...tlSearchType.data,
      ...tlInteraction.data,
    };
  }
}

export const tracking = new Tracking();
