import { cmp as ottoCmp, type Cmp } from "@otto-ec/eprivacy-cmp";

class SqrlConsentBanner {
  constructor(public isEnabled: boolean = false) {
    this.update();
  }

  private get cmp(): Cmp | null {
    if (ottoCmp && !!ottoCmp.getConsentForGV) {
      return ottoCmp;
    }
    return null;
  }

  update() {
    this.cmp?.getConsentForGV("OTTOT").then((ottot) => {
      this.isEnabled ||= ottot || false;
    });
    this.cmp?.getConsentForGV("OTTON").then((otton) => {
      this.isEnabled ||= otton || false;
    });
  }

  listenOnConsentChange() {
    this.cmp?.consentChanged.on(() => this.update());
  }
}

export const sqrlConsentBanner = new SqrlConsentBanner();
