export class StringUtil {
  escapeSpecials(text: string): string {
    const pattern = "\\\\";
    const regexp = new RegExp("[" + pattern + "]", "g");
    return text.replace(regexp, "");
  }

  compareIgnoreCase(textA: string, textB: string): boolean {
    if (textA == undefined || textB == undefined) {
      return textA == undefined && textB == undefined;
    }
    const decodedA = this.safeDecodeURIComponent(textA.toLowerCase());
    const decodedB = this.safeDecodeURIComponent(textB.toLowerCase());
    return this.trim(decodedA) === this.trim(decodedB);
  }

  private safeDecodeURIComponent(text: string): string {
    try {
      return decodeURIComponent(text);
    } catch (e) {
      return text;
    }
  }

  trimLeft(text: string): string {
    return text.replace(/^\s+/, "");
  }

  trimRight(text: string): string {
    return text.replace(/\s+$/, "");
  }

  trim(text: string): string {
    return text
      ?.trim()
      .split(/[\s,\t,\n]+/)
      .join(" ");
  }

  capitalize(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  unslugify(text: string): string {
    return text.replace(/ae/g, "ä").replace(/ue/g, "ü").replace(/oe/g, "ö").replace(/-/g, " ");
  }
}

export class GlobalUtil {
  setLocation(target: string) {
    (window as Window).location = target;
  }
}

export class CoreUtil {
  private value = 0;

  nextValue(): number {
    return ++this.value;
  }

  /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
  counter(start?: number): CoreUtil["nextValue"] {
    return this.nextValue.bind({ value: (start || 1) - 1 });
  }
}

export interface Util {
  string: StringUtil;
  global: GlobalUtil;
  core: CoreUtil;
}

export const util = {
  string: new StringUtil(),
  global: new GlobalUtil(),
  core: new CoreUtil(),
};
