import type { Debug } from "@otto-ec/global-resources";
import type { Log } from "../constants/log";

export class SqrlLogger<L extends Log> {
  constructor(private name: L) {}

  get logger(): Promise<Debug<L>> {
    return import("@otto-ec/global-resources/debug").then(({ logger }) => logger(this.name));
  }

  debug(message: string) {
    this.logger.then((log: Debug<L>) => log.debug(message));
  }
}
