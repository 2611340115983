import { derived, type Readable, writable, type Writable, get } from "svelte/store";

type SearchTerm = string;
type SearchTarget = string;

export class SearchfieldStore {
  value: Writable<SearchTerm> = writable("");
  target: Writable<SearchTarget> = writable("");
  isSuggestOpen: Writable<boolean> = writable(false);
  isFocus: Writable<boolean> = writable(false);
  isModified: Writable<boolean> = writable(false);

  get isEmpty(): Readable<boolean> {
    return derived(this.value, ($value: SearchTerm) => {
      if ($value.trim().length === 0) {
        this.isModified.set(false);
      }
      return $value.trim().length === 0;
    });
  }

  set $value(value: SearchTerm) {
    this.value.set(value);
  }

  get $value(): SearchTerm {
    return get(this.value);
  }

  set $target(value: SearchTarget) {
    this.target.set(value);
  }
}

export const searchfieldStore = new SearchfieldStore();
export const value = searchfieldStore.value;
export const target = searchfieldStore.target;
export const isModified = searchfieldStore.isModified;
export const isSuggestOpen = searchfieldStore.isSuggestOpen;
export const isFocus = searchfieldStore.isFocus;
