import { derived, get, type Readable, type Writable, writable } from "svelte/store";
import { activeIndex, isSuggestTypeFrozen } from "./IndexStore";
import { searchfieldStore } from "./SearchfieldStore";
import { submitHistory } from "./HistoryStore";
import { SqrlUrl } from "../util/SqrlUrl";
import { tracking } from "../tracking/Tracking";
import { SqrlGlobal } from "../util/SqrlGlobal";
import { util } from "../util/util";
import { tlInitialSearchBoxTime } from "../tracking/labels/InitialSearchBoxTime";
import type { HistoryItem } from "../types/global";

export enum SUBMIT_TYPE {
  NONE,
  ENTER,
  SUBMIT_BUTTON,
  SUGGEST_ITEM,
  HISTORY_ITEM,
  TRENDING_QUERY_ITEM,
}

export class SubmitStore {
  submitType: Writable<SUBMIT_TYPE> = writable(SUBMIT_TYPE.NONE);
  breadcrumbSearch: Writable<boolean> = writable(false);

  isActive: Readable<boolean> = derived(
    [activeIndex, isSuggestTypeFrozen],
    ([$activeIndex, $isSuggestTypeFrozen]) => {
      return $activeIndex === -1 && !$isSuggestTypeFrozen;
    },
  );

  /**
 *
 *
 *
 *
 *
 *
 *
 */
  handleSubmit(item: HistoryItem | { term: string }, target: string, submitType: SUBMIT_TYPE) {
    item.term = util.string.trim(item.term);
    if (item.term.length > 0) {
      this.submitType.set(submitType);
      searchfieldStore.isSuggestOpen.set(false);
      searchfieldStore.isFocus.set(false);
      submitHistory.addItem(item);

      tlInitialSearchBoxTime.setSessionStart();
      const url = new SqrlUrl(target);
      tracking.trackOnNextPageImpression(url.trackingData);
      SqrlGlobal.setLocation(url.urlWithoutTracking);
    }
  }

  isSubmitTypeIn(types: SUBMIT_TYPE[]): boolean {
    return types.includes(get(this.submitType));
  }

  isBreadcrumbSearch(): boolean {
    return get(this.breadcrumbSearch);
  }

  set $breadcrumbSearch(value: boolean) {
    this.breadcrumbSearch.set(value);
  }
}

export const submitStore = new SubmitStore();
export const isActive = submitStore.isActive;
